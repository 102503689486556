import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import { Container } from "../components/global";

import Header from "../components/sections/terms-privacy/header";
import Footer from "../components/sections/footer";

import { Content, Section, P, H2 } from "../styles/TCandPrivacyStyles";

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Service Level Agreement" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <Content>
        <Section>
          <H2>
            <span>OPay Business App Guarantees</span>
          </H2>
          <P>
            <span>1. Account Protection</span>
          </P>
          <P>
            If your OPay Business account contains any security risks due to misoperation, we will assist you in
            freezing the account to avoid fund loss.
          </P>
          <P>
            <span>2. Hardware Repair & Swap</span>
          </P>
          <P>OPay Business has a professional POS team to provide you with any POS repair or swap services.</P>
          <P>
            <span>3. Instant Payment Processing</span>
          </P>
          <P>OPay Business secures reliable, fast and safe transactions for all merchants on our platform.</P>
          <P>
            <span>4. Transaction Dispute Resolution</span>
          </P>
          <P>OPay Business operation team will deal with any transaction dispute within 48 hours.</P>
          <P>
            <span>5. Security guarantees</span>
          </P>
          <P>
            Strictly follow the relevant regulatory requirements, protect the user’s information security, and use
            secure encryption transmission for user data during the Internet transmission process to ensure data
            security and reliability.
          </P>
          <P>
            <span>6. 24/7 Customer Support</span>
          </P>
          <P>
            You can call the customer service hotline of OPay Business on 018888329 and get your queries answered in no
            time.
          </P>
        </Section>

        <Section>
          <H2>
            <span>OPay Personal App Guarantees</span>
          </H2>
          <P>
            <span>1. Account Protection</span>
          </P>
          <P>
            If your OPay personal account contains any security risks due to misoperation, we will assist you in
            freezing the account to avoid fund loss.
          </P>
          <P>
            <span>2. Security guarantees</span>
          </P>
          <P>
            Strictly follow the relevant regulatory requirements, protect the user’s information security, and use
            secure encryption transmission for user data during the Internet transmission process to ensure data
            security and reliability.
          </P>
          <P>
            <span>3. Instant Payment Processing</span>
          </P>
          <P>When you make a transaction such as a payment, transfer, or receipt, the funds arrive on time.</P>
          <P>
            <span>4. Transaction Dispute Resolution</span>
          </P>
          <P>OPay operations team will deal with any transaction dispute within 48 hours.</P>
          <P>
            <span>5. 24/7 Customer Support</span>
          </P>
          <P>If you encounter any problems, you can call OPay customer service at 234 700 888 8328</P>
        </Section>
      </Content>
    </Container>
    <Footer />
  </Layout>
);
export default TermsAndConditions;

const pageData = {
  headerData: {
    captionText: "Service Level Agreement",
    subTitleText: "Version 1.1 | 17th  March  2023",
  },
};
